import React, { useEffect, useState } from "react";
import useHttp from "../../hooks/useHttp";
import { urls } from "../../common/constants/Rutas";
import { replaceUrl } from "../../common/utilities/urls";
import {
  Card,
  Sorteo,
  Spacer,
} from "../../components";

export const Sorteos = (props) => {
  const { sendRequest, messages, isLoading } = useHttp();
  const [ sorteos, setSorteos ] = useState([]);

  useEffect(() => {
    sendRequest(
      {
        url: replaceUrl(`${urls.getSorteos}`, {
          todas: "true"
        }),
      },
      (data) => {
        setSorteos(data);
      }
    );
    // eslint-disable-next-line
  }, []);

  /** UI */
  return (
    <div className={"sorteos"}>
      <Card titulo={props.titulo}>
      <Spacer size={10} />
      </Card>
        { sorteos.map( item => <>
          <Sorteo sorteo={item} />
          <Spacer size={10} />
        </>) }
    </div>
  );
};
export default Sorteos;