import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { QrScanner } from "../../components/qr-scanner";

export const QrContext = createContext({});

export const QrProvider = (props) => {
  const location = useLocation();
  const [isEnabled, setIsEnabled] = useState(null);
  const [openLocation, setOpenLocation] = useState(false);

  useEffect(() => {
    if(isEnabled && openLocation && openLocation !== location?.hash) {
      setOpenLocation("");
      setIsEnabled(false);
    }
  }, [location]);

  const openQr = () => {
    setOpenLocation(location?.hash);
    setIsEnabled(true);
  }

  const onScan = (data) => {
    setIsEnabled(false);
  }

  return (
    <QrContext.Provider
      value={{
        openQr
      }}
    >
      {props.children}
      <QrScanner 
        enabled={isEnabled}
        onScan={onScan}
        onError={() => {}}
        onClose={() => { setIsEnabled(false)}}
      />
    </QrContext.Provider>
  );
};

export default QrProvider;
