import React, { createContext, useEffect, useState } from "react";
import { Modal } from "../../components";

export const ModalContext = createContext({});

export const ModalProvider = (props) => {
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [modalError, setModalError] = useState(null);
  const [modalMessages, setModalMessages] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTipo, setModalTipo] = useState(null);

  const open = (config) => {
    if(config?.tipo) {
      setModalIsOpen(true);
      setModalTipo(config.tipo);
    }
  }

  const close = () => {
      setModalIsOpen(false);
      setModalTipo(null);
  }
 
  return (
    <ModalContext.Provider
      value={{
        isModalLoading, 
        setIsModalLoading,
        setModalError,
        setModalMessages,
        open,
      }}
    >
      {props.children}
      <Modal 
        isLoading={isModalLoading}
        error={modalError}
        message={modalMessages}
        isOpen={modalIsOpen}
        tipo={modalTipo}
        handleClose={close}
      />
    </ModalContext.Provider>
  );
};

export default ModalProvider;
