import React from "react";
import {
  Spacer
} from "../../components";

export const Inicio = (props) => {
  /** UI */
  return (
    <div style={{margin: "10px"}}>
      <Spacer size={10} />
      <p className="inicio-label">Nos da mucho gusto darte la bienvenida💚, este es el sitio oficial de la plataforma <b>La del Pesito</b>.</p>
      <div className="inicio-logo" />
      <Spacer size={10} />
      <p className="inicio-label">En el menú principal de la esquina superior izquierda, podrás encontrar las opciones disponibles dentro del sitio, como consultar los sorteos activos o recientemente realizados, consultar los boletos adquiridos para cada sorteo, verificar el estatus de algún colaborador de la plataforma o la información para ponerte en contacto con nosotros.</p>
      <Spacer size={5} />
      <p className="inicio-label">En nuestra página oficial de <b><a className={"inicio-link"} href={"https://www.facebook.com/ladelpesito"} target={"_blank"}>Facebook (@ladelpesito)</a></b> puedes encontrar toda la información detallada sobre los sorteos, la dinámicas para obtener ganadores, manera de adquirir tus boletos.</p>
      <Spacer size={5} />
      <p className="inicio-label">En el menú principal de la esquina superior izquierda, podrás encontrar las opciones disponibles dentro del sitio, como consultar los sorteos activos o recientemente realizados, consultar los boletos adquiridos para cada sorteo, verificar el estatus de algún colaborador de la plataforma o la información para ponerte en contacto con nosotros.</p>
      <Spacer size={5} />
      <p className="inicio-label">En nuestra página oficial de <b><a className={"inicio-link"} href={"https://www.facebook.com/ladelpesito"} target={"_blank"}>Facebook (@ladelpesito)</a></b> puedes encontrar toda la información detallada sobre los sorteos, la dinámicas para obtener ganadores, manera de adquirir tus boletos.</p>
      <Spacer size={5} />
      <p className="inicio-label">En el menú principal de la esquina superior izquierda, podrás encontrar las opciones disponibles dentro del sitio, como consultar los sorteos activos o recientemente realizados, consultar los boletos adquiridos para cada sorteo, verificar el estatus de algún colaborador de la plataforma o la información para ponerte en contacto con nosotros.</p>
      <Spacer size={5} />
      <p className="inicio-label">En nuestra página oficial de <b><a className={"inicio-link"} href={"https://www.facebook.com/ladelpesito"} target={"_blank"}>Facebook (@ladelpesito)</a></b> puedes encontrar toda la información detallada sobre los sorteos, la dinámicas para obtener ganadores, manera de adquirir tus boletos.</p>
      <Spacer size={5} />
      <p className="inicio-label-large"><b>¡Gracias por tu apoyo!</b></p>
      <Spacer size={45} />
    </div>
  );
};
export default Inicio;