import { useCallback, useState, useEffect, useContext } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import { ModalContext } from "../providers/modal";
import { SettingsContext } from "../providers/settings";

//Hook que realizar requests a un API
export const useHttp = () => {
  const { setIsModalLoading, setModalError, setModalMessages } = useContext(ModalContext);
  const { settings, setSettings } = useContext(SettingsContext);
  const { get, set } = useLocalStorage();
  //Estados que manejan el estado de carga y error del request
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);
  const [messages, setMessages] = useState(null);

  useEffect(() => {
    return () => {
      // Previene error: Can't perform a React state update on an unmounted component
      setIsLoading(false);
      setError(null);
      setStatus(null);
      setMessages(null);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsModalLoading(isLoading);
    // eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    setModalError(error);
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    setModalMessages(messages);
    // eslint-disable-next-line
  }, [messages]);

  /**
   * Administra el envio y recepcion de un HTTP Request
   * @param {*} requestConfig - Configuracion del Request (url, headers, etc.)
   * @param {*} applyData - Aplica los datos dependiendo la respuesta
   */
  const sendRequest = useCallback(async (requestConfig, applyData, onError) => {
    //Se establece el estado de carga
    setIsLoading(true);
    //Se limpia el estado de error
    setError(null);
    setMessages(null);
    try {
      //Constante de respuesta
      let headers = {
        'Content-Type': 'application/json'
      }
      let setts = get("settings", true);
      if(setts?.token)
        headers.Authorization = `Bearer ${setts?.token}`;
      
      const response = await fetch(requestConfig.url, {
        method: requestConfig.method || "GET",
        headers,
        body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
      });
      setStatus(response.status);
      
      //Si la respuesta no es un Ok, evalua el objeto y devuelve un error
      if (!response.ok) {
        const res = await response.json();
        if (onError) {
          onError(res);
        }
        if(!requestConfig?.hideError) {
          setError(res?.error);
        }
        if(['EXPIRED_TOKEN', 'INVALID_TOKEN'].includes(res?.error?.codigo)) {
          setSettings({
            ...settings,
            token: null,
            sesion: null
          });
        }
      }
      else {
        //Se obtiene la respuesta de la llamada
        const res = await response.json();
        if (applyData) {
          applyData(res);
        }
      }
    } catch (err) {
      //En caso de error se regresa el objeto
      const errorBody = err.error || err.errors;
      const errorValue = errorBody || {
        errorCode: "500",
        error:
          "Se produjo un error al enviar o recuperar información del servidor",
      };
      if (onError) {
        onError(errorValue);
      }
      if(!requestConfig?.hideError) {
        setError(errorValue);
      }
    }
    //Se termina la carga
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Constante que limpia el estado de errores para modales
  const clearError = useCallback(async () => {
    setError(null);
  }, []);

  //Estados y funciones utilizables del hook
  return {
    isLoading,
    error,
    status,
    messages,
    clearError,
    sendRequest,
  };
};

export default useHttp;
