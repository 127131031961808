import React, { useEffect, useState, useContext } from "react";
import useHttp from "../../hooks/useHttp";
import useLocalStorage from "../../hooks/useLocalStorage";
import { SettingsContext } from "../../providers/settings";
import { urls } from "../../common/constants/Rutas";
import { replaceUrl } from "../../common/utilities/urls";
import { hoyStr } from "../../common/utilities/fechas";
import {
  ActionButton,
  ArrowDown,
  Card,
  DynamicControl,
  Boleto,
  NuevoBoleto,
  Spacer,
} from "../../components";

export const Dashboard = (props) => {
  const { get, set } = useLocalStorage();
  const { settings, setSettings } = useContext(SettingsContext);
  const { sendRequest: postLogin } = useHttp();
  const { sendRequest: getSorteos } = useHttp();
  const { sendRequest: getBoletos } = useHttp();
  const { sendRequest: postBoleto } = useHttp();
  const { sendRequest: fetchUser, isLoading: isLoadingUser } = useHttp();
  const [ loginData, setLoginData] = useState({});
  const [ enableLoginBtn, setEnableLoginBtn] = useState(false);
  const [ boletos, setBoletos] = useState([]);
  const [ sorteos, setSorteos ] = useState([]);
  const [ boletoData, setBoletoData] = useState({});
  const [ enableBoletoBtn, setEnableBoletoBtn] = useState(false);
  const [ telefonoFetched, setTelefonoFetched] = useState("");
  const [ existeUsuario, setExisteUsuario] = useState(false);

  /**
   * Obtener sorteos activos solo una vez al día
   */  
  useEffect(() => {
    let localSorteos = get('_sorteos', true, []);
    if(localSorteos?.fecha !== hoyStr()) {
      getSorteos(
        {
          url: replaceUrl(`${urls.getSorteos}`, {
            todas: "false"
          }),
        },
        (data) => {
          setSorteos(data);
          set('_sorteos', {
            registros: data,
            fecha: hoyStr()
          }, true)
          if(data?.length === 1) {
            setBoletoData((prev) => ({
              ...prev,
              sorteo_id: data?.[0]?.sorteo_id
            }));
          }
        }
      );
    }
    else{
      setSorteos(localSorteos?.registros);
      if(localSorteos?.registros?.length === 1) {
        setBoletoData((prev) => ({
          ...prev,
          sorteo_id: localSorteos?.registros?.[0]?.sorteo_id
        }));
      }
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Si se valida la sesión y es tipo usuario, se hace fetch de los boletos
   */
  useEffect(() => {
    if(settings?.token && settings?.sesion?.acceso_tipo === 1) {
      getBoletos(
        {
          url: urls.getBoletos
        },
        (data) => {
          setBoletos(data?.rows || []);
        }
      );
    }
  }, [settings]);

  useEffect(() => {
    setEnableLoginBtn(loginData?.telefono?.length > 9 && loginData?.password?.length > 4);
  }, [loginData]);

  useEffect(() => {
    setTelefonoFetched(boletoData?.telefono);
    setEnableBoletoBtn(boletoData?.sorteo_id > 0 && boletoData?.telefono?.length > 9 && boletoData?.cantidad > 0);
    // eslint-disable-next-line
  }, [boletoData]);
  
  useEffect(() => {
    if(telefonoFetched?.length > 9) {
      setBoletoData({
        ...boletoData,
        nombre: ''
      });
      setExisteUsuario(false);
      fetchUser(
        {
          url: replaceUrl(`${urls.getUsuarioNombre}`, boletoData),
        },
        (data) => {
          setBoletoData({
            ...boletoData,
            ...data
          });
          setExisteUsuario(data?.nombre);
        }
      );
    }
    // eslint-disable-next-line
  }, [telefonoFetched]);

  const handleLoginControlChange = (e) => {
    let data = { ...loginData };
    data[e?.target?.id] = e?.target?.value.trim();
    setLoginData(data);
  }

  const handleBoletoControlChange = (e) => {
    let data = { ...boletoData };
    data[e?.target?.id] = e?.target?.value.trim();
    setBoletoData(data);
  }

  const handleLogin = (e) => {
    postLogin(
      {
        url: urls.login,
        method: 'POST',
        body: loginData
      },
      (data) => {
        if(data?.bearer) {
          let { bearer, ...sesion } = data;
          setSettings({
            ...settings,
            token: bearer,
            sesion
          });
        }
      },
      (error) => {
        setSettings({
          ...settings,
          token: null,
          sesion: null
        });
      }
    );
  }

  const handleBoletoCreate = (e) => {
    postBoleto(
      {
        url: urls.postColaboradorBoleto,
        method: 'POST',
        body: boletoData
      },
      (data) => {
        setBoletoData({
          ...boletoData,
          telefono: '',
          nombre: '',
          apePat: '',
          apeMat: '',
          cantidad: ''
        });
      }
    );
  }

  const handleLogout = (e) => {
    setSettings({
      ...settings,
      token: null,
      sesion: null
    });
  }

  /** UI */
  return (
    <div className={"boletos"}>
      { !settings?.token &&
        <Card titulo={`Inicia Sesión`}>
          <DynamicControl 
            name={"telefono"}
            domain={"numero"}
            label={"Teléfono"}
            onChange={handleLoginControlChange} 
            maxLength={10}
          />
          <DynamicControl 
            name={"password"}
            domain={"password"}
            label={"Constraseña"}
            onChange={handleLoginControlChange}
          />
          <Spacer size={20} />
          <ActionButton
            name={"login"}
            disabled={!enableLoginBtn}
            onClick={handleLogin}
            label={"Iniciar sesión"}
          />
          <Spacer size={5} />
        </Card>
      }
      { settings?.token &&
        <>
          <Card titulo={`Hola${settings?.sesion?.nombre ? `, ${settings?.sesion?.nombre.split(' ')[0]}`: ''}!`}>
          </Card>
          <ArrowDown />
          <Spacer size={10} />
          { settings?.sesion?.acceso_tipo === 1  && 
            <>
              <NuevoBoleto venta={{}} />
              <Spacer size={10} />
              { boletos.map( venta => <Boleto venta={venta} />) }
            </>
          }
          { settings?.sesion?.acceso_tipo === 2  &&
            <Card titulo={`Capturar boleto`}>
              { sorteos?.length > 1 &&
                <DynamicControl 
                  name={"sorteo_id"}
                  domain={"select"}
                  label={"Sorteo"}
                  onChange={handleBoletoControlChange} 
                  dataSource={sorteos || []}
                  rowKey={"sorteo_id"}
                  rowNombre={"nombre"}
                  value={boletoData.sorteo_id}
                />
              }
              <DynamicControl 
                name={"telefono"}
                domain={"numero"}
                label={"Teléfono"}
                onChange={handleBoletoControlChange} 
                maxLength={10}
                value={boletoData.telefono || ""}
              />
              { boletoData?.telefono?.length > 9 && !isLoadingUser &&
                <>
                  <DynamicControl 
                    name={"nombre"}
                    domain={"nombre"}
                    label={"Nombre"}
                    readOnly={existeUsuario}
                    value={boletoData.nombre}
                    onChange={handleBoletoControlChange}
                  />
                  { !existeUsuario &&
                    <>
                      <DynamicControl 
                        name={"apePat"}
                        domain={"nombre"}
                        label={"Apellido paterno"}
                        onChange={handleBoletoControlChange} 
                        value={boletoData.apePat}
                      />
                      <DynamicControl 
                        name={"apeMat"}
                        domain={"nombre"}
                        label={"Apellido materno"}
                        onChange={handleBoletoControlChange} 
                        value={boletoData.apeMat}
                      />
                    </>
                  }
                  <DynamicControl 
                    name={"cantidad"}
                    domain={"numero"}
                    label={"Cantidad"}
                    onChange={handleBoletoControlChange} 
                    maxLength={5}
                    value={boletoData.cantidad}
                  />
                </>
              }
              <Spacer size={20} />
              <ActionButton
                name={"boleto"}
                disabled={!enableBoletoBtn}
                onClick={handleBoletoCreate}
                label={"Nuevo boleto +"}
              />
            </Card>
          }
          <Spacer size={20} />
          <ActionButton
            name={"logout"}
            onClick={handleLogout}
            label={"Cerrar sesión"}
          />
        </>
      }
    </div>
  );
};
export default Dashboard;