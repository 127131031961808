import history from "../history";
import AppRoutes from "./Routes";
import { CustomRouter } from "./CustomRouter";
import { paths } from "../common/constants/Rutas";
import { SettingsProvider } from "../providers/settings";
import { ModalProvider } from "../providers/modal";
import { QrProvider } from "../providers/qr";

function AppRouter() {
  return (
    <CustomRouter basename={paths.basename} history={history}>
        <SettingsProvider>
          <ModalProvider>
            <QrProvider>
              <AppRoutes />
            </QrProvider>
          </ModalProvider>
        </SettingsProvider>
    </CustomRouter>
  );
}

export default AppRouter;
