import React from "react";

export const ActionButton = ({
  nombre,
  onClick,
  label,
  icon,
  disabled,
  tipo = 1,
}) => {

  const handleOnClick = (e, value) => {
    if(onClick) {
      onClick(e, value);
    }
  }

  return (
    <button
      id={nombre}
      disabled={disabled}
      onClick={handleOnClick}
      className={`action-button ${tipo === 1 ? 'captura' : 'cancelar'}`}
    >{label}</button>
  );
};

export default ActionButton;
