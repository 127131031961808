import React, { useState, useContext } from "react";
import { ModalContext } from "../../providers/modal";
import { Card } from "../card";
import { getFechaDesc } from "../../common/utilities/fechas";

export const NuevoBoleto = () => {
  const { open } = useContext(ModalContext);
  
  return (
    <>
      <Card>
        <div 
          key={-1} 
          className={"nuevo-boleto"}
          onClick={() => open({tipo: 1})}
        >
          <p className={"nuevo-boleto-plus"}>+</p>
          <p className={"nuevo-boleto-desc"}>Generar nuevo boleto</p>
        </div>
      </Card>
    </>
  );
};

export default NuevoBoleto;
