import React, { useState, useContext } from "react";
import { QrContext } from "../../providers/qr";
import { paths } from "../../common/constants/Rutas";

export const MenuBar = () => {
  const { openQr } = useContext(QrContext);
  const opciones = [
    { id: 1, icono: "inicio", ruta: paths.inicio, titulo: "Inicio" },
    { id: 2, icono: "sorteos", ruta: paths.sorteos, titulo: "Sorteos" },
    { id: 4, icono: "qr", callback: openQr, titulo: "QR" },
    { id: 5, icono: "contacto", ruta: paths.contacto, titulo: "Contacto" },
    { id: 6, icono: "usuario", ruta: paths.miCuenta, titulo: "Mi cuenta" },
  ];

  const onClick = (opcion) => {
    if(opcion?.callback) {
      opcion.callback();
    }
    else {
      document.location = opcion.ruta;
      document.title = `${opcion.titulo} - La del pesito`;
    }
  }

  return (
    <div className={"menuBar"}>
      {
        opciones.map(opcion =>
          <button
            key={opcion.id}
            className="menuBar-opcion"
            onClick={() => onClick(opcion)}
          >
            <div className={`menuBar-icon icon-${opcion.icono}`} />
          </button>
        )
      }
    </div>
  );
};

export default MenuBar;
