import React, { useEffect, useState } from "react";
import {
  ActionButton,
  Card,
  DynamicControl,
  Spacer,
} from "../../components";

const Modal = ({
  isLoading,
  error,
  message,
  isOpen,
  tipo,
  handleClose,
  handleSubmit
}) => {

  const [ showError, setShowError ] = useState(false);
  const [ showSucess, setShowSuccess ] = useState(false);

  useEffect(() => {
    console.log('isOpen: ', isOpen);
    // eslint-disable-next-line
  }, [isOpen])

  useEffect(() => {
    console.log('tipo: ', tipo);
    // eslint-disable-next-line
  }, [tipo])

  useEffect(() => {
    if(error) {
      setShowError(true);
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    if(message) {
      setShowSuccess(true);
    }
    // eslint-disable-next-line
  }, [message])

  const onClose = () => {
    setShowError(false);
    setShowSuccess(false);
  }

  return (
    <>
      { isLoading &&
        <div
          key={"loading-modal"}
          className={"loading-modal"}
        >
          <img alt={""} src={"./media/loading.gif"} className={"loading-icon"}></img>
        </div>
      }
      { showError &&
        <div className={"error-modal"} >
          <div className={"card error-window"} >
            <div className={"error-banner"}>
              <span class={"material-symbols-rounded error-icon"}>error</span>
            </div>
            <div className={"error-desc"}>{`${error?.descripcion}`}</div>
            <button className={"error-button"} onClick={onClose} >Cerrar</button>
          </div>
        </div>
      }
      { showSucess &&
        <div className="success-modal" >
          <div className="card success-window" >
            <div className="success-banner">
              <span class="material-symbols-rounded success-icon">check_circle</span>
            </div>
            <div className="success-desc">{`${message?.descripcion}`}</div>
            <button className="success-button" onClick={onClose} >Ok</button>
          </div>
        </div>
      },
      {(isOpen && tipo === 1) &&
        <div
          key={"loading-modal"}
          className={"loading-modal"}
          onClick={() => {
            if(handleClose) {}
              //handleClose()
          }}
        >
          <Card>
            <DynamicControl 
              name={"cantidad"}
              domain={"numero"}
              label={"Cantidad"}
              //onChange={handleBoletoControlChange} 
              maxLength={5}
              //value={boletoData.cantidad}
            />
            <Spacer size={20} />
            <ActionButton
              name={"boleto"}
              //disabled={!enableBoletoBtn}
              //onClick={handleBoletoCreate}
              label={"Confirmar"}
            />
            <ActionButton
              name={"boleto"}
              onClick={()=> {handleClose()}}
              label={"Cancelar"}
              tipo={2}
            />
          </Card>
        </div>
      }
    </>
  );
};
export default Modal;
