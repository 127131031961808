import React, { useEffect, useState } from "react";
import QrReader from 'react-qr-scanner'

export const QrScanner = ({
  enabled,
  onScan,
  onError,
  onClose
}) => {

  const [isHidding, setIsHidding] = useState(false);

  useEffect(() => {
    if(enabled === true) {
      setIsHidding(false);
    }
    else if(enabled === false) {
      setIsHidding(true);
      setTimeout(()=> {
        setIsHidding(false);
      }, 500)
    }
  }, [enabled])

  const handleScan = (e) => {
    if (e?.text && onScan) {
      let result = {};
      console.log('e?.text', e?.text);
      let data = e?.text.split('_');
      let operacion = data?.[0];
      let registro = data?.[1];
      switch(operacion) {
        case 'CLBRR':
        case 'CLBRV':
          console.log(`Verifica colaborador "${data?.[1]}"`);
          result = {
            operacion,
            registro
          }
          break;
        default: 
          console.log('No se reconoce la operación');
          break;
      }
      onScan(result);
    }
  }

  const handleError = (e) => {
    if (e?.text && onError) {
      console.log('onError e:', e);
    }
  }

  const close = () => {
    if(onClose) 
      onClose();
  } 

  return (
    <>
      { (enabled || isHidding) && 
        <div 
          className={`qr-modal qr-modal-fade-${isHidding ? 'out' : 'in'}`}
          onClick={close}
        >  
          <div className={`qr-camera qr-camera-fade-${isHidding ? 'out' : 'in'}`}>
            <div className={`qr-frame`} /> {/* qr-frame-fade-${isHidding ? 'out' : 'in'}`}></div>*/}
            <QrReader
              delay={500}
              style={{
                height: 230,
                width: 230,
              }}
              constraints={{
                audio: false,
                video: { 
                  facingMode: "environment",
                  aspectRatio: 1,
                }
              }}
              onError={handleError}
              onScan={handleScan}
            />
          </div>
        </div>
      }
    </>
  );
};

export default QrScanner;
