import { useLocation } from "react-router-dom";

export const useLocalStorage = () => {
  
  const location = useLocation();

  const get = (key, global = false, def = '') => {
    let hashHasParams = (location?.hash.indexOf('?') > 0);
    let hash = `${location?.hash.substring(0, hashHasParams ? location?.hash.indexOf('?') : location?.hash.length).replace('#', '_')}_`;
    let value = localStorage.getItem(`${global ? '' : hash}${key}`) || def;
    try {
      value = JSON.parse(value);
    }
    catch(e) {
      // do nothing
    }
    return value;
  }
  
  const set = (key, value, global) => {
    if(typeof value === 'object') value = JSON.stringify(value);
    let hashHasParams = (location?.hash.indexOf('?') > 0);
    let hash = `${location?.hash.substring(0, hashHasParams ? location?.hash.indexOf('?') : location?.hash.length).replace('#', '_')}_`;
    localStorage.setItem(`${global ? '' : hash}${key}`, value);
  }

  //Estados y funciones utilizables del hook
  return {
    get,
    set
  };
};

export default useLocalStorage;
