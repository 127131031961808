import React from "react";
import { Card } from "../card";
import { Chip } from "../chip";
import { getFechaDesc } from "../../common/utilities/fechas";

export const Sorteo = ({
  sorteo,
  ...props
}) => {
  return (
    <Card>
      <div 
        key={sorteo.sorteo_id} 
        className={"sorteo"}
      >
        <p className={"sorteo-nombre"}>{`${sorteo?.nombre}`}</p>
        <p className={"sorteo-desc"}>{`${sorteo?.descripcion}`}</p>
        <div className={"sorteo-activo"}>
          <Chip 
            item={{
              color: sorteo?.activo ? "#0D616F" : "#707070",
              desc: sorteo?.activo ? "Por realizar" : "Realizado",
              width: "82px",
            }}
          />
        </div>
        <p className={"sorteo-fecha"}>{`${sorteo?.activo ? "A realizar": "Realizado"}: ${sorteo?.final.split('T')[0]}`}</p>
        <p className={"sorteo-bolsa"}>{`Bolsa acumulada: `}<b>{`$${sorteo?.bolsa}.00`}</b></p>
      </div>
    </Card>
  );
};

export default Sorteo;
