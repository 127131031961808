import React, { createContext, useState } from "react";
import { localStorageData } from "../../common/constants/Settings";
import useLocalStorage from "../../hooks/useLocalStorage";

export const SettingsContext = createContext({});

export const SettingsProvider = (props) => {
  const { get, set } = useLocalStorage();
  const [settingsData, setSettingsData] = useState(get(localStorageData.SETTINGS, true, {}));
  
  const setSettings = (data) => {
    set(localStorageData.SETTINGS, data, true);
    setSettingsData(data);
  }

  return (
    <SettingsContext.Provider
      value={{
        settings: settingsData,
        setSettings
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
