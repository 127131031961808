import React from "react";
import { Outlet } from "react-router-dom";
import MenuBar from "../../components/menu-bar";

export default function HomeLayout(props) {
  return (
    <>
      <div className={"mainLayout"}>
        <Outlet />
      </div>
      <MenuBar />
    </>
  );
}
