import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import useLocalStorage from "../../hooks/useLocalStorage";
import { urls } from "../../common/constants/Rutas";
import { replaceUrl } from "../../common/utilities/urls";
import {
  ActionButton,
  ArrowDown,
  ColaboradorCard,
  Card,
  DynamicControl,
  Modal,
  NoEncontrado,
  Spacer,
  QrScanner
} from "../../components";

export const Colaborador = (props) => {
  const { get, set } = useLocalStorage();
  const [searchParams] = useSearchParams();
  const { sendRequest, messages, isLoading, clearError } = useHttp();
  const [colaborador, setColaborador] = useState(null);
  const [codigo, setCodigo] = useState(searchParams.get("codigo") || get("codigo") || "");
  const [enableSearch, setEnableSearch] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [fetchedCodigo, setFetchedCodigo] = useState("");
  const [openQr, setOpenQr] = useState(false);

  useEffect(() => {
    setEnableSearch(codigo?.length === 4);
    // eslint-disable-next-line
  }, [codigo]);

  useEffect(() => {
    if (messages)
      setFetched(true);
    // eslint-disable-next-line
  }, [messages]);

  const handleControlChange = (e) => {
    setCodigo(e?.target?.value.trim().toUpperCase());
  }

  const handleOnClick = (e) => {
    setColaborador(null);
    setFetched(false);
    clearError();
    consultarEmpleado(codigo);
  }

  const consultarEmpleado = (valor) => {
    sendRequest(
      {
        url: replaceUrl(`${urls.getColaborador}`, { codigo: valor }),
      },
      (data) => {
        setFetched(true);
        setFetchedCodigo(valor);
        setColaborador(data.rows[0]);
      }
    );
  }

  const handleOnClicQR = (e) => {
    setColaborador(null);
    setCodigo('');
    setFetched(false);
    clearError();
    setOpenQr(true);
  }

  const onScan = (data) => {
    console.log('data', data);
    setOpenQr(false);
    setCodigo(data?.codigo);
    consultarEmpleado(data?.codigo)
  }

  /** UI */
  return (
    <div className={"boletos"}>
      <Card titulo={props.titulo}>
        <DynamicControl
          name={"codigo"}
          domain={"codigo"}
          label={"Código"}
          value={codigo}
          onChange={handleControlChange}
          maxLength={4}
        />
        <Spacer size={20} />
        <ActionButton
          name={"consulta"}
          disabled={!enableSearch}
          onClick={handleOnClick}
          label={"Verificar"}
        />
        <Spacer size={20} />
        <ActionButton
          name={"launchQR"}
          onClick={handleOnClicQR}
          label={"Leer QR"}
        />
        <Spacer size={5} />
      </Card>
      {fetched && <>
        <ArrowDown />
        {!messages ?
          <ColaboradorCard colaborador={colaborador} codigo={fetchedCodigo} /> :
          <NoEncontrado desc={messages?.error?.nombre} />
        }
      </>
      }
      { openQr && 
        <QrScanner 
          enabled={openQr}
          onScan={onScan}
        />
      }
    </div>
  );
};
export default Colaborador;