import React from "react";
import { Card } from "../card";
import { getFechaDesc } from "../../common/utilities/fechas";

export const Boleto = ({
  venta,
  ...props
}) => {
  return (
    <Card>
      <div 
        key={venta.venta_id} 
        className={"boleto"}
      >
        <div className={"boleto-cantidad"}>
          <div className="boleto-imagen"></div>
          <div className="boleto-numero">{venta.cantidad}</div>
          { venta.extras > 0 && <div className="boleto-extras">{`+${venta.extras}`}</div> }
        </div>
        <div className="boleto-separador"/>
        <div className={"boleto-desc"} >
          <div className={"boleto-desc-item"}>
            <span className="material-symbols-rounded">confirmation_number</span>
            <p className={"boleto-desc-label"}><b>{`# ${venta?.num_inicial}${venta?.num_inicial < venta?.num_final ? ` - ${venta.num_final}` : ''}`}</b></p>
          </div>
          <div className={"boleto-desc-item"}>
            <span className="material-symbols-rounded">badge</span>
            <p className={"boleto-desc-label"}>{`${venta?.colaborador}`}</p>
          </div>
          <div className={"boleto-desc-item"}>
            <span className="material-symbols-rounded">event</span>
            <p className={"boleto-desc-label"}>{getFechaDesc(venta?.fecha)}</p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Boleto;
