import appConfig from "../../../config/app";

export const paths = {
  inicio: "#Inicio",
  sorteos: "#Sorteos",
  contacto: "#Contacto",
  miCuenta: "#MiCuenta"
};

export const urls = {
  apiUrl: appConfig.API_URL,
  login: appConfig.API_URL + "login",
  getSorteos: appConfig.API_URL + "sorteos?todas={todas}",
  // Usuario
  getBoletos: appConfig.API_URL + "usuario/boletos",
  getColaborador: appConfig.API_URL + "usuario/colaborador/{codigo}",
  // Colaborador
  getUsuarioNombre: appConfig.API_URL + "colaborador/usuario/{telefono}",
  postColaboradorBoleto: appConfig.API_URL + "colaborador/venta/crear",
};
